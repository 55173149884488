*{
    margin: 0;
    padding: 0;
    font-family: "Euclid Circular A Regular";
}
.active
{
    color:#B9625D;
    font :bold;
}
@media only screen and (max-width: 768px) {
    .active {
        color: #574848;
    }
}

